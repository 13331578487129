import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Content, { HTMLContent, HTMLContentAST } from '../components/Content';
import Layout from '../components/Layout';
import Title from '../components/Title';
import * as styles from './generic-page.module.scss';

export const GenericPageTemplate = ({
  layout,
  content,
  contentComponent,
  title,
  helmet,
  image,
  attribution,
}) => {
  const PostContent = contentComponent || Content;
  const PageLayout = layout || Layout;

  return (
    <PageLayout backdropThree>
      <div className={`after-nav ${styles.mainContent}`}>
        {helmet || ''}
        <div className={styles.pageHeader}>
          <section className={`${styles.pageSection} ${styles.animatedDate}`}>
            <Title accentBar text={title} size="1" animated />
          </section>
        </div>
        {image != null ? (
          <div className={styles.pageImage}>
            <section
              className={`${styles.pageSection} ${styles.animatedThumbnail}`}
            >
              {image.childImageSharp != null ? (
                <div>
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt=""
                  />
                  <HTMLContent
                    content={attribution}
                    className={styles.imgAttribution}
                  />
                </div>
              ) : (
                <div>
                  <img alt="" src={image} />
                  <HTMLContent content={attribution} />
                </div>
              )}
            </section>
          </div>
        ) : (
          ''
        )}
        <div className={styles.pageContent}>
          <section className={styles.pageSection}>
            <div className={styles.articleContent}>
              <PostContent content={content} />
            </div>
          </section>
        </div>
      </div>
    </PageLayout>
  );
};

GenericPageTemplate.propTypes = {
  layout: PropTypes.func,
  content: PropTypes.object.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string.isRequired,
  helmet: PropTypes.object,
  image: PropTypes.object,
  attribution: PropTypes.string,
};

GenericPageTemplate.defaultProps = {
  layout: null,
  contentComponent: null,
  helmet: null,
  image: null,
  attribution: '',
};

class GenericPage extends React.Component {
  constructor(props) {
    super(props);
    this.popDownNavBar = React.createRef();
    this.post = props.data.markdownRemark;
  }

  componentDidMount() {
    document.documentElement.classList.toggle('noscroll', false);
  }

  render() {
    return (
      <GenericPageTemplate
        content={this.post.htmlAst}
        contentComponent={HTMLContentAST}
        date={this.post ? this.post.frontmatter.date : ''}
        image={this.post ? this.post.frontmatter.image : null}
        attribution={this.post.frontmatter.attribution || undefined}
        helmet={
          <Helmet titleTemplate="%s | Lab3 Apps">
            <title>{`${this.post ? this.post.frontmatter.title : ''}`}</title>
            <meta
              name="description"
              content={`${this.post ? this.post.frontmatter.description : ''}`}
            />
            <meta property="og:title" content={this.post.frontmatter.title} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="/" />
            {this.post.frontmatter.image ? (
              <meta
                property="og:image"
                content={`https://lab3apps.com${this.post.frontmatter.image.childImageSharp.gatsbyImageData.src}`}
              />
            ) : (
              ''
            )}

            <meta
              property="og:description"
              content={this.post.frontmatter.description}
            />
          </Helmet>
        }
        tags={this.post ? this.post.frontmatter.tags : ''}
        title={this.post ? this.post.frontmatter.title : ''}
      />
    );
  }
}

GenericPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default GenericPage;

export const pageQuery = graphql`
  query GenericPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        title
        description
        tags
        attribution
        image {
          childImageSharp {
            gatsbyImageData(
              width: 700
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;
