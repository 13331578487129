// extracted by mini-css-extract-plugin
export var accentFadeIn = "generic-page-module--accent-fade-in--8khAx";
export var animatedDate = "generic-page-module--animated-date--UnQ7B";
export var animatedThumbnail = "generic-page-module--animated-thumbnail--6gfqS";
export var articleContent = "generic-page-module--article-content--LOimB";
export var contactFormWrapper = "generic-page-module--contact-form-wrapper--AK382";
export var contactSubmittedEntry = "generic-page-module--contact-submitted-entry--0ASbM";
export var fadeIn = "generic-page-module--fade-in--h1-Rs";
export var fadeInThumbnail = "generic-page-module--fade-in-thumbnail--J65bo";
export var hidden = "generic-page-module--hidden---LTc0";
export var imgAttribution = "generic-page-module--img-attribution--hfq+r";
export var mainContent = "generic-page-module--main-content--oYZwb";
export var page = "generic-page-module--page--G9XHw";
export var pageContent = "generic-page-module--page-content--CDkuu";
export var pageHeader = "generic-page-module--page-header--aD9Us";
export var pageImage = "generic-page-module--page-image--n+-as";
export var pageSection = "generic-page-module--page-section--tgUAA";
export var titleFadeIn = "generic-page-module--title-fade-in--8gVgm";